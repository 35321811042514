<template>
  <v-tooltip bottom max-width="200">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        elevation="1"
        small
        :class="background"
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon small :color="iconColor">
          {{ icon }}
        </v-icon>
      </v-btn>
    </template>
    <span>
      {{ span }}
    </span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "emoticon-poop"
    },
    span: {
      type: String,
      default: "Tooltip de icon"
    },
    background: {
      type: String,
      default: "amber lighten-3"
    },
    iconColor: {
      type: String,
      default: "amber darken-2"
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>
